@import 'variables';

.eself-input {
  border-radius: 0.625rem;
  border: 1px solid #eaeaea;
  background: #fff;
  box-shadow: 0px 10px 40px 0px rgba(174, 174, 174, 0.2);
  color: #333;
  font-family: $main-font;
  font-size: $basic-font-size;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1rem;

  &::placeholder,
  &::-webkit-input-placeholder,
  :-ms-input-placeholder {
    color: #b0a9a9;
  }
}
