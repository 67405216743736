@import 'variables';

.btn.btn-primary {
  background: $primary-color;
  border: 1px solid #eaeaea;
  color: #fff;

  &:hover {
    background-color: #343350;
    transition: 0.7s;
  }
  &:focus {
    outline-color: transparent;
    outline-style: solid;
    box-shadow: 0 0 0 4px $primary-color;
  }
  &:active {
    background-color: #202036;
  }
  &:disabled {
    background-color: darkgray;
    color: white;
    opacity: 0.7;
  }
}

.eself-text-button {
  background-color: transparent;
  border: none;
  border-radius: 0.625rem;
  width: 100%;
  text-align: center;
  color: #333;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: Plus Jakarta Sans;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &:hover {
    background-color: #343350;
    border-color: $secondary-color;
    transition: 0.7s;
    color: white;
  }
  &:focus {
    outline-color: transparent;
    outline-style: solid;
    box-shadow: 0 0 0 4px $primary-color;
    background-color: #343350;
    transition: 0.7s;
    color: white;
  }
  &:active {
    background-color: #202036;
    transition: 0.7s;
    color: white;
  }
  &:disabled {
    background-color: darkgray;
    color: white;
    opacity: 0.7;
  }
}

.btn-outline-primary {
  border: 1px solid $secondary-color;
  color: black;
  &:hover {
    background-color: #343350;
    border-color: $secondary-color;
    transition: 0.7s;
    color: white;
  }
  &:focus {
    outline-color: transparent;
    outline-style: solid;
    box-shadow: 0 0 0 4px $primary-color;
    background-color: #343350;
    transition: 0.7s;
    color: white;
  }
  &:active {
    background-color: #202036;
    transition: 0.7s;
    color: white;
  }
  &:disabled {
    background-color: darkgray;
    color: white;
    opacity: 0.7;
  }
}

.eself-primary-button {
  cursor: pointer;
  border-radius: 0.625rem;
  border: 1px solid #eaeaea;
  background: $primary-color;
  box-shadow: 0px 10px 40px 0px rgba(174, 174, 174, 0.2);
  padding: 1rem;
  color: #fff;
  font-family: $main-font;
  font-size: $basic-font-size;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  &:hover {
    background-color: #343350;
    transition: 0.7s;
  }
  &:focus {
    outline-color: transparent;
    outline-style: solid;
    box-shadow: 0 0 0 4px $primary-color;
  }
  &:active {
    background-color: #202036;
  }
  &:disabled {
    background-color: darkgray;
    color: white;
    opacity: 0.7;
  }
}

.eself-accent-button {
  cursor: pointer;
  border-radius: 0.625rem;
  border: 1px solid #eaeaea;
  background: $secondary-color;
  box-shadow: 0px 10px 40px 0px rgba(174, 174, 174, 0.2);
  padding: 1rem;
  color: #353535;
  font-family: $main-font;
  font-size: $basic-font-size;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  &:hover {
    background-color: #bd913b;
    transition: 0.7s;
  }
  &:focus {
    outline-color: transparent;
    outline-style: solid;
    box-shadow: 0 0 0 4px $primary-color;
  }
  &:active {
    background-color: #a07c33;
  }
  &:disabled {
    background-color: darkgray;
    color: white;
    opacity: 0.7;
  }
}

.eself-secondary-button {
  cursor: pointer;
  border-radius: 0.625rem;
  border: 1px solid #eaeaea;
  background: #fff;
  box-shadow: 0px 10px 40px 0px rgba(174, 174, 174, 0.2);
  padding: 1rem;
  color: #333;
  font-family: $main-font;
  font-size: $basic-font-size;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  &:hover {
    background-color: #e9e9e9;
    transition: 0.7s;
  }
  &:focus {
    outline-color: transparent;
    outline-style: solid;
    box-shadow: 0 0 0 4px $secondary-color;
  }
  &:active {
    background-color: #a5a5a5;
  }
  &:disabled {
    background-color: darkgray;
    color: white;
    opacity: 0.7;
  }
}
