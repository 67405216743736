@use 'buttons';
@use 'forms';
@use 'layout';
@use 'text';
@import '_variables';

@font-face {
  font-family: $main-font;
  src: url('/assets/fonts/PlusJakartaSans-VariableFont_wght.ttf');
}
@font-face {
  font-family: $outfit-font;
  src: url('/assets/fonts/Outfit-VariableFont_wght.ttf');
}

* {
  box-sizing: border-box;
}
input::placeholder {
  color: #a8a8a8;
}
html,
body {
  color: #000;
  font-family: $main-font;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-check-input:checked {
  background-color: $primary-color;
  border-color: $primary-color;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(77, 13, 253, 0.25);
}

.pointer {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.shadow-on-hover:hover {
  text-shadow: 1px 1px 2px #a8a8a875;
}
.outfit-font {
  font-family: $outfit-font;
}
.main-font {
  font-family: $main-font;
}

input.ng-valid {
  border-left: 2px solid darkgreen;
}

input.ng-invalid {
  border-left: 2px solid darkred;
}

.card {
  border-color: #e3e6ef;
  & > .card-body {
    background-color: white;
  }

  & > .card-header {
    background-color: white;
    border-bottom: 0px;
  }

  & > .card-footer {
    background-color: white;
    border-top: 0px;
  }

  border-radius: 6px;
  --bs-card-inner-border-rdaius: 2px;
}
